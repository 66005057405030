import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ParsingService } from "./parsing.service";
import { ImportIncentive, Incentive } from "../models";

@Injectable({
  providedIn: "root"
})
export class IncentivesService {

  constructor(private http: HttpClient, private parsingService: ParsingService) { }


  /**
   * TODO: Change incentives structure to work with the new structer from the csv import
   * including tiered subvention, money factors
   * how does TFSCash apply? :TFS APR Std Rate Cash is the TFSCash field. It can be applied as a rebate if the customer chooses to finance the vehicle at standard TFS rates instead of using TFS incentive rates. Obviously, it can only be used when financing through TFS and not another financial institution. It's another one of those oddball rebates that usually only applies to one or two models.
   */

  importIncentives(importIncentiveArray: ImportIncentive[]) {


    importIncentiveArray = this.convertDataTypes(importIncentiveArray);
    const models = importIncentiveArray.map((importIncentive: ImportIncentive) => {

      const incentive: Incentive = {} as Incentive;
      if (!importIncentive.Description) {
        return;
      }
      const splitDescription = importIncentive.Description.split(" ");
      const model = splitDescription[ 0 ];
      splitDescription.splice(0, 1);
      const trim = splitDescription.join(" ");

      incentive.title = importIncentive.Description;
      incentive.vehicleQualifierList = [
        {
          year: (importIncentive.Year || 0).toString(),
          make: "Toyota",
          modelNumber: importIncentive[ "Model#" ],
          model,
          trim
        }
      ];
      incentive.leaseOffer = {
        leaseOfferTerms: [
          {
            term: 24,
            tieredLeaseMFs: [
              importIncentive[ "T1+24MF" ],
              importIncentive.T124MF,
              importIncentive.T224MF,
              importIncentive.T324MF,
            ],
            leaseSubvention: importIncentive[ "24moLeaseSubvention" ],
            cashAtSigning: 0,
            rcf: 0
          },
          {
            term: 36,
            tieredLeaseMFs: [
              importIncentive[ "T1+36MF" ],
              importIncentive.T136MF,
              importIncentive.T236MF,
              importIncentive.T336MF,
            ],
            leaseSubvention: importIncentive[ "36moLeaseSubvention" ],
            cashAtSigning: 0,
            rcf: 0
          },
          {
            term: 39,
            tieredLeaseMFs: [
              importIncentive[ "T1+36MF" ],
              importIncentive.T136MF,
              importIncentive.T236MF,
              importIncentive.T336MF,
            ],
            leaseSubvention: importIncentive[ "36moLeaseSubvention" ],
            cashAtSigning: 0,
            rcf: 0
          }
        ]

      };
      const financeRates = [36, 48, 60, 66, 72].map(term => {
        const financeRate = this.financeRateFilter(importIncentive, term);
        return financeRate;
      }).filter(financeRate => financeRate);

      incentive.financeOffer = {
        // map through terms that apply to finance rate,
        // if the rates are all null, set the financeRates value to null
        financeRates: financeRates.length ? financeRates : null,
        aprSubventionTiers: this.aprSubventionTiersFilter(importIncentive)
      };

      incentive.cashDetails = {
        bonusCash: importIncentive.BonusCash,
        customerCash: importIncentive.CustCash,
        leaseCash: importIncentive.TFSLeaseCash,
        financeCash: importIncentive.TFSCash,
        militaryRebate: importIncentive.Military,
        collegeRebate: importIncentive.College,
        otherRebate: 0,
        customerLoyalty500: importIncentive.CustomerLoyalty500,
        customerLoyalty1000: importIncentive.CustomerLoyalty1000,
        uberRebate: importIncentive.UberRebate
      };

      return incentive;
    });

    return models.filter(model => model);

  }

  convertRawPercentToDecimal(percent) {
    if (percent == null) {
      return null;
    }
    const converted = parseFloat((percent * 1000 * .00001).toFixed(5));
    return converted;

  }

  // converts all data types tot he proper types
  convertDataTypes(importIncentiveArray: ImportIncentive[]): ImportIncentive[] {
    return importIncentiveArray.map((importIncentive: ImportIncentive) => {
      Object.keys(importIncentive).forEach(key => {
        const strings = ["Description", "Expires", "Model#"];
        if (!strings.includes(key)) {
          importIncentive[ key ] = parseFloat(importIncentive[ key ]);
          if (isNaN(importIncentive[ key ])) {
            importIncentive[ key ] = null;
          }
        }
      });
      return importIncentive;
    });
  }

  aprSubventionTiersFilter(importIncentive) {
    const aprSubventiontiers = [
      importIncentive[ "T1+APRSubvention" ],
      importIncentive[ "T1-APRSubvention" ],
      importIncentive[ "T2-APRSubvention" ],
      importIncentive[ "T3-APRSubvention" ]
    ];
    return aprSubventiontiers.filter(tier => tier !== null).length > 0 ? aprSubventiontiers : null;
  }

  // if rates in tieredRates are all null, then set the financeRate entry to null
  financeRateFilter(importIncentive, term) {
    let tieredRates = [
      this.convertRawPercentToDecimal(importIncentive[ "T1+" + term + "APR" ]),
      this.convertRawPercentToDecimal(importIncentive[ "T1-" + term + "APR" ]),
      this.convertRawPercentToDecimal(importIncentive[ "T2-" + term + "APR" ]),
      this.convertRawPercentToDecimal(importIncentive[ "T3-" + term + "APR" ]),
    ];
    const filteredTieredRates = tieredRates.filter((rate: number) => rate !== null);
    tieredRates = filteredTieredRates.length > 0 ?
      filteredTieredRates :
      null;
    if (tieredRates) {
      return {
        minYear: parseInt(importIncentive.Year || 0, 10),
        term,
        tieredRates
      };
    }
    return null;
  }


  // const sampleOfData = [{
  //   "24moLeaseSubvention": "600",
  //   "36moLeaseSubvention": "600",
  //   "College": "500",
  //   "CustCash": "1500",
  //   "Description": "Corolla 4 Door Sedan 'L'",
  //   "Expires": "05-04-2020",
  //   "Military": "500",
  //   "Model#": "1831",
  //   "T1+24MF": ".00019",
  //   "T1+36APR": "0.9",
  //   "T1+36MF": ".00019",
  //   "T1+48APR": "0.9",
  //   "T1+60APR": "0.9",
  //   "T1+72APR": " 1.9 ",
  //   "T1+APRSubvention": " 500 ",
  //   "T1-36APR": "0.9",
  //   "T1-48APR": "0.9",
  //   "T1-60APR": "0.9",
  //   "T1-72APR": "1.9",
  //   "T1-APRSubvention": "500",
  //   "T2-36APR": "2.9",
  //   "T2-48APR": "2.9",
  //   "T2-60APR": "2.9",
  //   "T2-72APR": "3.9",
  //   "T2-APRSubvention": "500",
  //   "T3-36APR": "5.9",
  //   "T3-48APR": "5.9",
  //   "T3-60APR": "5.9",
  //   "T3-72APR": "6.9",
  //   "T3-APRSubvention": "500",
  //   "T124MF": ".00029",
  //   "T136MF": ".00029",
  //   "T224MF": ".00074",
  //   "T236MF": ".00074",
  //   "T324MF": ".00164",
  //   "T336MF": ".00164",
  //   "TFSCash": "",
  //   "Year": "2020"
  // }];

}
