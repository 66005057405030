<div class="app-page-container p-0">
  <app-writeups-list
    [deals]="deals"
    [loading]="loading"
    [currentUser]="currentUser"
    [searchText]="searchText"
    [selectedManagerId]="selectedManagerId"
    [isSalesManager]="false"
    (dealSelected)="onSelectDeal($event)"
  >
  </app-writeups-list>
</div>
