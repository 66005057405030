<form [formGroup]="searchForm"
      class="inset-input-group">
  <input class="form-control form-control-sm rounded-pill" style="border-radius: 4px!important;"
         formControlName="searchString"
         type="text"
         (keyup)="onSubmitSearchForm()"
         [maxlength]="REQS.maxLength.standard"
         [placeholder]="placeholder"/>
  <button class="app-btn-success rounded-circle inset-btn" style="border-radius: 4px 0 0 4px!important;"
          type="submit"
          (click)="onSubmitSearchForm()">
    <i class="material-icons">search</i>
  </button>
</form>
