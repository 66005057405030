import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { DealState, DealStatus, DealType } from "src/app/clearpath-module/store/state";
import { FinanceOptions, TradeIn } from "src/app/clearpath-module/models";
import { CalculationService } from "src/app/clearpath-module/services";
import { DealIncentive, VehicleIncentive } from "src/app/settings-module/models/incentives";
import { DealDefaults } from "src/app/clearpath-module/services/deal/deal-defaults.service";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectNetTradeInValue } from "../../../../clearpath-module/store/selectors/deal";
import { Lender } from "../../../models/data";

@Component({
  selector: "app-cash-form",
  templateUrl: "./cash-form.component.html",
  styleUrls: ["./cash-form.component.scss"]
})
export class CashFormComponent implements OnInit, OnChanges {
  @Input() financeOptions: FinanceOptions;
  @Input() dealIncentives: DealIncentive[];
  @Input() vehicleIncentives: VehicleIncentive[];
  @Input() dealDefaults: DealDefaults;
  @Input() lienHolder: Lender;
  @Input() tradeIn: TradeIn;
  @Input() verified: boolean;

  @Input() set dealStatus(status: string) { this.syncFormStatus(status); }

  @Output() submitDealType = new EventEmitter<string>();
  @Output() submitIncentives = new EventEmitter<DealIncentive[]>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();

  private uiState = {completedDeal: false};

  tradeInNetValue$: Observable<number> = this.store.select(selectNetTradeInValue);

  totalVehicleCashPrice$ = this.calcService.totalVehicleCashPrice$();
  calcTax$ = this.calcService.calcTax$({finance: true});

  lender;

  constructor(
    private store: Store<DealState>,
    private calcService: CalculationService
  ) { }

  ngOnInit() {
    this.lender = this.lienHolder;
  }

  ngOnChanges() {
    this.totalVehicleCashPrice$ = this.calcService.totalVehicleCashPrice$();
    this.lender = this.lienHolder;
  }

  private syncFormStatus(status: string) {
    this.uiState.completedDeal = status === DealStatus.Completed;
  }

  /*onUpdateIncentives(incentives: DealIncentive[]) {
    this.submitIncentives.emit(incentives);
  }*/

  get cashPurchase(): boolean {
    return this.financeOptions.cashPurchase;
  }

  get completedDeal(): boolean {
    return this.uiState.completedDeal;
  }

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  };
}
