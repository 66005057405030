import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { DealService } from "../../../../clearpath-module/services";
import { DealState, DealType } from "../../../../clearpath-module/store/state";
import { Lender } from "../../../models/data";
import { LENDERS } from "../../../data/lenders";
import { initialLienHolderState } from "../../../../clearpath-module/store/state/deal/financing";

@Component({
  selector: 'app-deal-type-selector',
  templateUrl: './deal-type-selector.component.html',
  styleUrls: ['./deal-type-selector.component.scss']
})
export class DealTypeSelectorComponent implements OnInit, OnDestroy {
  @Output() submitDealType = new EventEmitter<string>();
  private unsubscribe$ = new Subject();
  private deal: DealState;
  private loaded: boolean = false;

  formGroup: FormGroup = this.formBuilder.group({
    selectedDealType: [undefined],
  });

  constructor(private formBuilder: FormBuilder, private dealService: DealService,) { }

  ngOnInit(): void {
    this.dealService.selectDeal().pipe(
      takeUntil(this.unsubscribe$),
      map(dealState => {
        this.deal = dealState;
        let newDealType: string | undefined = undefined;
        if (this.deal.financeOptions.financeSelected) {
          newDealType = DealType.Finance;
        } else if (this.deal.leaseOptions.leaseSelected) {
          newDealType = DealType.Lease;
        } else if (this.deal.financeOptions.cashPurchase) {
          newDealType = DealType.Cash;
        }
        this.formGroup.get('selectedDealType')?.setValue(newDealType);
        if (!this.loaded && this.deal) {
          this.loaded = true;
          this.updateLienHolder(newDealType);
        }
      })).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get lenders(): Lender[] {
    return LENDERS;
  }

  getIndexForLenderName(lenderName: string): number {
    const lenderIndex = this.lenders.findIndex(lender => lender.name === lenderName);
    // console.log('getIndexForLenderName', lenderName, lenderIndex);
    return lenderIndex;
  }

  onSelectedDealTypeChange(event: any) {
    const newDealType: string = event.value;
    // console.log('onSelectedDealTypeChange', newDealType);
    this.formGroup.get('selectedDealType')?.setValue(newDealType);
    this.updateLienHolder(newDealType);
  }

  updateLienHolder(newDealType: any) {
    switch (newDealType) {
      case DealType.Finance:
        this.submitDealType.emit(DealType.Finance);
        if (this.deal.lienHolder.name === "*TOYOTA LEASE TRUST") {
          this.dealService.dispatchSetLienHolder(this.lenders[ this.getIndexForLenderName("*TOYOTA MOTOR CREDIT CORPORATION") ]);
        }
        break;
      case DealType.Lease:
        this.submitDealType.emit(DealType.Lease);
        if (this.deal.lienHolder.name !== "*TOYOTA LEASE TRUST") {
          this.dealService.dispatchSetLienHolder(this.lenders[ this.getIndexForLenderName("*TOYOTA LEASE TRUST") ]);
        }
        break;
      case DealType.Cash:
        this.submitDealType.emit(DealType.Cash);
        this.dealService.dispatchSetLienHolder({...initialLienHolderState});
        break;
    }
  }
}
