<div class="app-page-container" style="padding:0;">
  <app-writeups-list
    [deals]="deals"
    [loading]="loading"
    [searchText]="searchText"
    [currentUser]="currentUser"
    [selectedManagerId]="selectedManagerId"
    (dealSelected)="onSelectDeal($event)"
    [isSalesManager]="true">
  </app-writeups-list>
</div>
