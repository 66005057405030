<h5 class="h5 app-text-neutral">{{ incentiveGroupName }}</h5>

<div *ngIf="exclusions.length > 0">
  <em
    *ngFor="let exclusion of exclusions"
    class="d-block mb-1 mt-1 ml-auto mr-auto"
  >
    {{ exclusion }}
  </em>
</div>

<div *ngIf="showCash || showFinance || showLease" class="incentive pt-3">
  <div
    *ngIf="showCash"
    class="incentive-card app-bg-lt-gray app-border-gray"
    style="min-height: 300px"
  >
    <h5 class="h5 pt-3 pl-3 pb-3 app-text-neutral">Cash</h5>
    <h2 class="h2 app-text-secondary text-center mb-0">
      {{ customerCash | currency }}
    </h2>
    <div class="text-center m-auto">Customer Cash</div>
    <div
      class="incentive-card-button position-absolute d-flex justify-content-center align-items-center"
    >
      <button
        class="btn"
        [ngClass]="
          selectedOffers.customerCash ? 'app-btn-secondary' : 'app-btn-neutral'
        "
        (click)="onToggleIncentive('customerCash', customerCash)"
      >
        {{ selectedOffers.customerCash ? "Unselect" : "Select" }}
      </button>
    </div>
  </div>

  <div
    *ngIf="showFinance"
    class="incentive-card app-bg-lt-gray app-border-gray"
  >
    <h5 class="h5 pt-3 pl-3 app-text-neutral">Finance</h5>
    <form [formGroup]="financeTierForm" class="px-3 mb-0 form-group" style="padding-top:0;">
      <div *ngIf="aprSubventionTiers.length" class="subvention-block">
        <h2 class="h2 app-text-secondary text-center mb-0">
          {{ aprSubventionTiers[0] | currency }}
        </h2>
        <div class="text-center m-auto">APR Subvention</div>
        <div class="text-center m-auto and-block">AND</div>
      </div>
      <select class="form-control" formControlName="selectedCreditTier">
        <option *ngFor="let tier of creditTiers; let i = index" [value]="i">
          {{ tier }}
        </option>
      </select>
      <div *ngIf="financeRatesExistForTier" class="mt-3">
        <div *ngFor="let rate of financeRates" class="text-center">
          {{ getFinanceRate(rate.tieredRates) | percent: "1.1-2" }} for
          {{ rate.term }} months
        </div>
        <em class="d-block m-2 ml-auto mr-auto text-center">On approved credit.</em>
      </div>
    </form>
    <div class="incentive-card-button position-absolute d-flex justify-content-center align-items-center">
      <button
        class="btn"
        [ngClass]="isAprSubventionBuffer(!financeOptions.aprSubventionDisabled) ? 'app-btn-secondary' : 'app-btn-neutral'"
        (click)="onToggleAprSubvention(!financeOptions.aprSubventionDisabled)">
        {{ isAprSubventionBuffer(!financeOptions.aprSubventionDisabled) ? "Unselect" : "Select" }}
      </button>
    </div>
  </div>

  <div
    *ngIf="showLease"
    class="incentive-card app-bg-lt-gray app-border-gray"
    style="min-height: 300px"
  >
    <h5 class="h5 pt-3 pl-3 app-text-neutral">Lease</h5>
    <!--form [formGroup]="leaseTierForm" class="p-3 mb-0 form-group">
      <select
        *ngIf="leaseOffer.leaseOfferTerms.length > 0"
        class="form-control"
        formControlName="selectedCreditTier"
      >
        <option *ngFor="let tier of creditTiers; let i = index" [value]="i">
          {{ tier }}
        </option>
      </select>
    </form-->

    <div *ngIf="leaseRatesExistForTier" class="mt-3">
      <!--div *ngIf="maxLeaseOfferMonths" class="text-center">
        <em class="d-block m-2 ml-auto mr-auto text-center"
        >Special Lease Offer for leases up to
          {{ maxLeaseOfferMonths }} months</em
        >
      </div-->

      <div *ngFor="let leaseOfferTerm of this.leaseOfferTerms; let i = index" style="padding:0 18px;">
        <div *ngIf="showLeaseSubventionTerm(leaseOfferTerm.term) && leaseOfferTerm.leaseSubvention>0"
             style="background: #fff;border:1px solid #999;flex-direction: column;gap:16px;padding:10px;justify-content: center">
          {{ leaseOfferTerm.term }} Month Lease
          <div class="app-text-secondary font-weight-bold mb-1" style="font-size:20px">{{ leaseOfferTerm.leaseSubvention| currency }}</div>
          <button
            class="btn"
            [ngClass]="
          isLeaseOfferTermBuffer(leaseOfferTerm,leaseOptions.selectedLeaseTerm,leaseOptions.subventionCashDisabled) ? 'app-btn-secondary' : 'app-btn-neutral'"
            (click)="onToggleLeaseSubvention(leaseOfferTerm,leaseOptions.subventionCashDisabled)">
            {{ isLeaseOfferTermBuffer(leaseOfferTerm, leaseOptions.selectedLeaseTerm, leaseOptions.subventionCashDisabled) ? "Unselect" : "Select" }}
          </button>
        </div>
      </div>
    </div>

    <!--div class="text-center">
      <div *ngIf="leaseSubventionOffer" class="mt-3">
        <div class="text-center m-auto">AND</div>
        <h2 class="h2 app-text-secondary text-center mb-0">
          {{ leaseSubventionOffer | currency }}
        </h2>
        <div class="text-center m-auto">Lease Subvention</div>
      </div>
    </div>
  </div-->

    <!--div
      class="incentive-card-button position-absolute d-flex justify-content-center align-items-center">
      <button
        class="btn"
        [ngClass]="selectedOffers.leaseOffer ? 'app-btn-secondary' : 'app-btn-neutral'"
        (click)="onToggleIncentive('leaseOffer', leaseOffer)">
        {{ selectedOffers.leaseOffer ? "Unselect" : "Select" }}
      </button>
    </div-->
  </div>
</div>

<em class="d-block mb-3 mt-4 ml-auto mr-auto" *ngIf="dealerFinancingRequired">
  You must finance or lease with Toyota Financial Services to qualify for the
  following:
</em>

<hr/>

<form [formGroup]="cashOffersForm">

  <section class="cash-offer-container">

    <div class="cash-offer-box">
      <div>
        <input type="checkbox" formControlName="militaryRebate"/>
      </div>
      <label>
        <div>Military Rebate</div>
        <div class="price">
          {{ militaryRebate | currency }}
        </div>
      </label>
    </div>

    <div class="cash-offer-box">
      <div>
        <input type="checkbox" formControlName="collegeRebate"/>
      </div>
      <label>
        <div>College Rebate</div>
        <div class="price">
          {{ collegeRebate | currency }}
        </div>
      </label>
    </div>

    <div class="cash-offer-box">
      <div>
        <input type="checkbox" formControlName="uberRebate"/>
      </div>
      <label>
        <div>Uber Rebate</div>
        <div class="price">
          {{ uberRebate | currency }}
        </div>
      </label>
    </div>

    <div *ngIf="bonusCash" class="cash-offer-box">
      <div>
        <input type="checkbox" formControlName="bonusCash"/>
      </div>
      <label>
        <div>Bonus Cash</div>
        <div class="price">
          {{ bonusCash | currency }}
        </div>
      </label>
    </div>

    <div *ngIf="otherRebate" class="cash-offer-box">
      <div>
        <input type="checkbox" formControlName="otherRebate"/>
      </div>
      <label>
        <div>
          {{ collegeRebate || militaryRebate ? "Other " : "" }} Rebate
        </div>
        <div class="price">
          {{ otherRebate | currency }}
        </div>
      </label>
    </div>

    <div *ngIf="leaseCash" class="cash-offer-box">
      <div>
        <input type="checkbox" formControlName="leaseCash"/>
      </div>
      <label>
        <div>Lease Cash</div>
        <div class="price">
          {{ leaseCash | currency }}
        </div>
      </label>
    </div>

    <div class="cash-offer-box">
      <div>
        <input type="checkbox" formControlName="customerLoyalty500"/>
      </div>
      <label>
        <div>Finance Loyalty</div>
        <div class="price">
          {{ customerLoyalty500 | currency }}
        </div>
      </label>
    </div>

    <div *ngIf="financeCash" class="cash-offer-box">
      <div>
        <input type="checkbox" formControlName="financeCash"/>
      </div>
      <label>
        <div>Finance Cash</div>
        <div class="price">
          {{ financeCash | currency }}
        </div>
      </label>
    </div>

    <div class="cash-offer-box">
      <div>
        <input type="checkbox" formControlName="customerLoyalty1000"/>
      </div>
      <label>
        <div>Lease Loyalty</div>
        <div class="price">
          {{ customerLoyalty1000 | currency }}
        </div>
      </label>
    </div>

  </section>

</form>
