<body id="spacing">
<div
  *ngIf="loading"
  class="d-flex app-text-success justify-content-center align-items-center my-5"
>
  <span class="spinner-border spinner-border-sm mr-1"></span>
  Loading Deals...
</div>

<section *ngIf="!loading" class="data-table-container">
  <table class="data-table mx-auto w-100">
    <tr style="background: #eee;">
      <th
        class="selectable"
        style="width: 55px;pointer-events: none"
        [ngClass]="{ selected: isActiveSort('alerts') }"
        (click)="onSelectSort('alerts')"
      >
        <div class="d-flex align-items-center">
          Alerts
          <!-- <i *ngIf="isActiveSort('alerts')" class="material-icons">
          {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
        </i> -->
        </div>
      </th>

      <th *ngIf="isSalesManager" class="selectable" style="width: 70px;pointer-events: none">
        <div class="d-flex align-items-center">Activity</div>
      </th>

      <th
        class="selectable"
        style="width: auto"
        [ngClass]="{ selected: isActiveSort('customer') }"
        (click)="onSelectSort('customer')"
      >
        <div class="d-flex align-items-center">
          Customer
          <i *ngIf="isActiveSort('customer')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        style="width: auto"
        [ngClass]="{ selected: isActiveSort('stockNumber') }"
        (click)="onSelectSort('stockNumber')"
      >
        <div class="d-flex align-items-center">
          Stock
          <i *ngIf="isActiveSort('stockNumber')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        [ngClass]="{ selected: isActiveSort('vehicle') }"
        (click)="onSelectSort('vehicle')"
      >
        <div class="d-flex align-items-center">
          Vehicle
          <i *ngIf="isActiveSort('vehicle')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        *ngIf="isSalesManager"
        class="selectable"
        style="width: 105px"
        [ngClass]="{ selected: isActiveSort('state') }"
        (click)="onSelectSort('state')"
      >
        <div class="d-flex align-items-center">
          State
          <i *ngIf="isActiveSort('state')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        style="width: 145px"
        [ngClass]="{ selected: isActiveSort('salesPerson') }"
        (click)="onSelectSort('salesPerson')"
      >
        <div class="d-flex align-items-center">
          Sales Person
          <i *ngIf="isActiveSort('salesPerson')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        style="width: 145px"
        [ngClass]="{ selected: isActiveSort('salesManager') }"
        (click)="onSelectSort('salesManager')"
      >
        <div class="d-flex align-items-center">
          Sales Manager
          <i *ngIf="isActiveSort('salesManager')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        style="width: 100px"
        [ngClass]="{ selected: isActiveSort('status') }"
        (click)="onSelectSort('status')"
      >
        <div class="d-flex align-items-center">
          Status
          <i *ngIf="isActiveSort('status')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        [ngClass]="{ selected: isActiveSort('statusDate') }"
        (click)="onSelectSort('statusDate')"
        style="width: 110px"
      >
        <div class="d-flex align-items-center">
          Status Date
          <i *ngIf="isActiveSort('statusDate')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>
    </tr>

    <ng-container *ngIf="salesManagerDeals.length">
      <tr
        *ngFor="
            let deal of salesManagerDeals
              | dealFilter: searchText
              | teamFilter: selectedManagerId
              | dealSort: sortParam:ascending;
            let i = index
          "
        class="selectable"
        [ngClass]="{ 'app-bg-lt-blue': hasAlert(deal) }"
        (click)="onSelectDeal(deal)"
      >
        <td style="text-align: center">
          <i *ngIf="hasAlert(deal)" class="material-icons app-" style="text-align: center!important;"
          >error</i
          >
        </td>
        <td *ngIf="isSalesManager" (click)="onSelectDealHistory(deal); $event.stopPropagation()">
          <app-activity></app-activity>
        </td>
        <td class="app-text-success" style="width: 150px">
          {{ deal.customer.firstName }} {{ deal.customer.lastName }}
        </td>
        <td>{{ deal.stockNumber }}</td>
        <td>{{ deal.vehicle | vehicleName }}</td>
        <td *ngIf="isSalesManager">{{ deal.customer.state?.toUpperCase() || "" }}</td>
        <td>{{ deal.salesId ? deal.salesPerson : "---" }}</td>
        <td>{{ this.getSalesManagerName(deal) }}</td>
        <td>{{ deal.status?.toUpperCase() }}</td>
        <td>{{ deal.statusDate | date }}</td>
      </tr>
    </ng-container>

    <ng-container *ngIf="otherDeals.length">
      <tr
        *ngFor="
            let deal of otherDeals
              | dealFilter: searchText
              | teamFilter: selectedManagerId
              | dealSort: sortParam:ascending;
            let i = index
          "
        class="selectable"
        (click)="onSelectDeal(deal)"
        [ngClass]="{ 'app-bg-lt-blue': hasAlert(deal) }"
      >
        <td style="text-align: center">
          <i *ngIf="hasAlert(deal)" class="material-icons app-text-blue" style="text-align: center!important;color:#215eb0!important;font-weight:600"
          >error</i
          >
        </td>
        <td *ngIf="isSalesManager" (click)="onSelectDealHistory(deal); $event.stopPropagation()">
          <app-activity></app-activity>
        </td>
        <td class="app-text-success">
          {{ deal.customer.firstName }} {{ deal.customer.lastName }}
        </td>
        <td>{{ deal.stockNumber }}</td>
        <td>{{ deal.vehicle | vehicleName }}</td>
        <td *ngIf="isSalesManager">{{ deal.customer.state?.toUpperCase() || "" }}</td>
        <td>{{ deal.salesId ? toTitleCase(deal.salesPerson) : "---" }}</td>
        <td>{{ toTitleCase(this.getSalesManagerName(deal)) }}</td>
        <td>{{ deal.status?.toUpperCase() }}</td>
        <td>{{ deal.statusDate | date }}</td>
      </tr>
    </ng-container>

    <ng-container *ngIf="deals.length && !otherDeals.length">
      <tr
        *ngFor="
            let deal of dealList
              | dealFilter: searchText
              | teamFilter: selectedManagerId
              | dealSort: sortParam:ascending;
            let i = index
          "
        class="selectable"
        [ngClass]="{ 'app-bg-lt-blue': hasAlert(deal) }"
        (click)="onSelectDeal(deal)"
      >
        <td style="text-align: center">
          <i *ngIf="hasAlert(deal)" class="material-icons app-text-blue" style="text-align: center!important;color:#215eb0!important;font-weight:600"
          >error</i
          >
        </td>
        <td *ngIf="isSalesManager" (click)="onSelectDealHistory(deal); $event.stopPropagation()">
          <app-activity></app-activity>
        </td>
        <td class="app-text-success">
          {{ deal.customer.firstName }} {{ deal.customer.lastName }}
        </td>
        <td>{{ deal.stockNumber }}</td>
        <td>{{ deal.vehicle | vehicleName }}</td>
        <td *ngIf="isSalesManager">{{ deal.customer.state?.toUpperCase() || "" }}</td>
        <td>{{ deal.salesId ? toTitleCase(deal.salesPerson) : "---" }}</td>
        <td>{{ toTitleCase(this.getSalesManagerName(deal)) }}</td>
        <td>{{ deal.status?.toUpperCase() }}</td>
        <td>{{ deal.statusDate | date }}</td>
      </tr>
    </ng-container>
  </table>
</section>
</body>
