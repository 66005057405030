import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CalculationService, DealIncentivesService, DealService, LeaseCalculationService, VehicleService } from "src/app/clearpath-module/services";
import { DealState, DealStatus, DealType } from "src/app/clearpath-module/store/state";
import { LeaseOptions, LeaseResidual, TradeIn, VehicleNeeds } from "src/app/clearpath-module/models";
import { LeaseDefault } from "src/app/settings-module/models";
import { DealIncentive, VehicleIncentive } from "src/app/settings-module/models/incentives";
import { DealDefaults } from "src/app/clearpath-module/services/deal/deal-defaults.service";
import { pathOr } from "ramda";
import { combineLatest, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LeaseTerm } from "src/app/clearpath-module/models/calculations";
import { selectNetTradeInValue } from "../../../../clearpath-module/store/selectors/deal";
import { Store } from "@ngrx/store";
import * as dealActions from "../../../../clearpath-module/store/actions/deal.actions";
import { Lender } from "../../../models/data";

@Component({
  selector: "app-lease-form",
  templateUrl: "./lease-form.component.html",
  styleUrls: ["./lease-form.component.scss"]
})
export class LeaseFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() leaseOptions: LeaseOptions;

  @Input() set dealStatus(status: string) { this.syncFormStatus(status); }

  @Input() leaseResiduals: LeaseResidual[];
  @Input() lienHolder: Lender;
  @Input() dealDefaults: DealDefaults;
  @Input() leaseDefault: LeaseDefault;
  @Input() vehicleCondition: string;
  @Input() dealIncentives: DealIncentive[];
  @Input() vehicleIncentives: VehicleIncentive[];
  @Input() selectedCreditTier: number;
  @Input() tradeIn: TradeIn;
  @Output() submitDealType = new EventEmitter<string>();
  @Output() submitLeaseOptions = new EventEmitter<Partial<LeaseOptions>>();
  @Output() submitLeaseOptionsNonFlagUpdate = new EventEmitter<Partial<LeaseOptions>>();
  @Output() submitLeasingTerm = new EventEmitter<number>();
  @Output() submitIncentives = new EventEmitter<DealIncentive[]>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();
  lender;
  milesDrivenPerYear: number;
  plannedLengthOfOwnership: number;

  @Input()
  set vehicleNeeds(vehicleNeeds: VehicleNeeds) {
    this.milesDrivenPerYear = vehicleNeeds.milesDrivenPerYear;
    this.plannedLengthOfOwnership = vehicleNeeds.plannedLengthOfOwnership;
  }

  private unsubscribe$ = new Subject();
  uiState = {
    subventionCash: 0,
    acquisitionFee: 0,
    vehicle: null,
    completedDeal: false,
    leaseSelected: false,
    selectedLeaseTerm: 0,
    approved: false,
    prepaidMilesTotal: 0,
    rateMarkup: 0,
    leaseOptions: null,
  };

  toggleSubventionCashDisabled = () => {
    this.leaseForm.get('subventionCashDisabled').setValue(!this.leaseForm.get('subventionCashDisabled').value);
    const leaseOptions: Partial<LeaseOptions> = {
      subventionCashDisabled: this.leaseForm.get('subventionCashDisabled').value
    };
    //console.log("Lease Options Update:",leaseOptions)
    this.submitLeaseOptions.emit(leaseOptions);
  };

  leaseForm: FormGroup = this.formBuilder.group({
    acquisitionFee: [0, Validators.min(0)],
    rateMarkup: [0, Validators.min(0)],
    subventionCash: [0, Validators.min(0)],
    moneyFactor24: [0, Validators.min(0)],
    moneyFactor30: [0, Validators.min(0)],
    moneyFactor36: [0, Validators.min(0)],
    moneyFactor39: [0, Validators.min(0)],
    moneyFactor42: [0, Validators.min(0)],
    moneyFactor48: [0, Validators.min(0)],
    moneyFactor60: [0, Validators.min(0)],
    subventionCashDisabled: [true]
  });

  miscLeaseForm: FormGroup = this.formBuilder.group({
    additionalCashDue: [0, Validators.min(0)]
  });

  excessMilesForm: FormGroup = this.formBuilder.group({
    prepaidMilesTotal: [0, Validators.min(0)],
    excessMiles: [0, Validators.min(0)],
  });

  // Calculation Selectors
  tradeInNetValue$: Observable<number> = this.store.select(selectNetTradeInValue);

  // Calculation Observables
  adjustedCapCost$ = this.leaseCalcService.adjustedCapCost$();
  calcTax$ = this.calcService.calcTax$({leaseSelected: true});
  totalLeaseMonthlyPayment$ = this.leaseCalcService.calcTotalMonthlyLeasePayment$;
  excessMilesPerYear$ = this.leaseCalcService.calcExcessMiles$();
  moneyFactor$ = this.leaseCalcService.moneyFactor$;

  constructor(
    private store: Store<DealState>,
    private formBuilder: FormBuilder,
    private leaseCalcService: LeaseCalculationService,
    private calcService: CalculationService,
  ) { }

  // INITIALIZATION

  ngOnInit() {
    this.lender = this.lienHolder;
    this.leaseCalcService.calcPrepaidMilesTotal$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(ppm => {
        this.uiState.prepaidMilesTotal = ppm;
        this.excessMilesForm.patchValue({prepaidMilesTotal: ppm});
      });
    this.excessMilesPerYear$ = this.leaseCalcService.calcExcessMiles$();
    this.syncLeaseOptions(this.leaseOptions);
    combineLatest([
      this.moneyFactor$(24),
      this.moneyFactor$(30),
      this.moneyFactor$(36),
      this.moneyFactor$(39),
      this.moneyFactor$(42),
      this.moneyFactor$(48),
      this.moneyFactor$(60)
    ]).subscribe((
      [
        moneyFactor24,
        moneyFactor30,
        moneyFactor36,
        moneyFactor39,
        moneyFactor42,
        moneyFactor48,
        moneyFactor60
      ]) => {
      this.leaseForm.patchValue({
        moneyFactor24,
        moneyFactor30,
        moneyFactor36,
        moneyFactor39,
        moneyFactor42,
        moneyFactor48,
        moneyFactor60
      });
    });

    const newMarkup = pathOr(null, ["newMarkup"], this.leaseDefault);
    const certUsedMarkup = pathOr(null, ["certUsedMarkup"], this.leaseDefault);
    const rateMarkup = this.vehicleCondition === "new" ? newMarkup : certUsedMarkup;
    this.uiState.rateMarkup = rateMarkup;
    this.leaseForm.patchValue({rateMarkup});
  }

  ngOnChanges() {
    this.lender = this.lienHolder;
    // this.leaseForm.reset();
    this.syncLeaseOptions(this.leaseOptions);
    // if (this.leaseOfferSelected) {
    this.leaseForm.patchValue({
      subventionCash: this.leaseCash
    });
    this.uiState.subventionCash = this.leaseCash;
    // }
    this.adjustedCapCost$ = this.leaseCalcService.adjustedCapCost$();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private syncLeaseOptions(options: LeaseOptions) {
    const aqFee = options.acquisitionFee || this.leaseDefault.acquisitionFee;
    this.uiState.leaseSelected = options.leaseSelected;
    this.uiState.selectedLeaseTerm = options.selectedLeaseTerm;

    if (options.rateMarkup) {
      this.leaseForm.patchValue({
        rateMarkup: options.rateMarkup,
        subventionCashOverride: options.subventionCashOverride,
        acquisitionFee: aqFee
      });
    }
    this.leaseForm.patchValue({
      subventionCashOverride: options.subventionCashOverride,
      subventionCashDisabled: options.subventionCashDisabled
    });
    this.uiState.acquisitionFee = aqFee;
    this.leaseForm.patchValue({
      acquisitionFee: aqFee
    });
    this.miscLeaseForm.patchValue({additionalCashDue: options.additionalCashDue});
    if (this.leaseSelected) {
      this.leaseForm.enable();
      this.miscLeaseForm.enable();
      this.excessMilesForm.enable();
    } else {
      this.leaseForm.disable();
      this.miscLeaseForm.disable();
      this.excessMilesForm.disable();
    }
  }

  /*checkBoxColor(option): string {
    if (this.activeLeaseTerm(option.term)) {
      return "app-bg-success";
    }
    return "";
  }*/

  private syncFormStatus(status: string) {
    const completedDeal = status === DealStatus.Completed;
    this.uiState.completedDeal = completedDeal;
    if (completedDeal) {
      this.leaseForm.disable();
      this.miscLeaseForm.disable();
      this.excessMilesForm.disable();
    }
  }

  activateIncentive() {
    // find incentive index
    const i = this.dealIncentives.findIndex(inc => {
      return this.leaseIncentive && inc.incentiveGroupName === this.leaseIncentive.incentiveGroupName;
    });
    // if incentive is not applied then
    if (i === -1) {
      if (this.leaseIncentive) {
        this.dealIncentives.push({
          incentiveGroupName: this.leaseIncentive.incentiveGroupName,
          leaseOffer: this.leaseIncentive.incentive.leaseOffer
        });
      }
      this.submitIncentives.emit(undefined);
    }
  }

  // UI RENDERING

  activeLeaseTerm(term: number): boolean {
    return term === this.uiState.selectedLeaseTerm;
  }

  get completedDeal(): boolean {
    return this.uiState.completedDeal;
  }

  get leaseSelected(): boolean {
    const {leaseSelected} = this.uiState;
    return leaseSelected === true;
  }

  get leaseCash(): number {
    if (this.leaseOptions.subventionCashOverride) {
      return this.leaseOptions.subventionCashOverride;
    }
    if (!this.leaseIncentive) { return 0; }
    const offer = this.getOfferForTerm();
    if (offer) {
      return offer.leaseSubvention;
    } else {
      return 0;
    }
  }

  getOfferForTerm() {
    let leaseTerm: number = this.uiState.selectedLeaseTerm;
    const leaseIncentiveLeaseOfferTerms = pathOr(0, [
        "incentive",
        "leaseOffer",
        "leaseOfferTerms"
      ],
      this.leaseIncentive
    );
    if (!this.leaseSelected && this.leaseTerms?.length > 0) {
      leaseTerm = this.leaseTerms[ 0 ].term;
    }
    return leaseIncentiveLeaseOfferTerms.find(t => t.term === leaseTerm);
  }

  /*get leaseMFOffer(): number {
    if (!this.leaseIncentive) { return 0; }
    const offer = this.getOfferForTerm();
    if (offer) {
      return offer.tieredLeaseMFs[ this.selectedCreditTier ];
    } else {
      return 0;
    }
  }*/

  get leaseIncentive(): VehicleIncentive {
    return this.vehicleIncentives.find(ig => {
      const i = ig.incentive;
      const leaseOfferTerms = pathOr([], ["leaseOffer", "leaseOfferTerms"], i);
      return leaseOfferTerms.length > 0;
    });
  }

  get defaultTerms(): number[] {
    const types = {new: "newTerms", certified: "certUsedTerms"};
    const termType = types[ this.vehicleCondition ] || "";
    return this.leaseDefault[ termType ]?.sort() || [];
  }

  get allAvailableTerms(): number[] {
    return this.leaseResiduals.reduce((terms: number[], residual: LeaseResidual) => {
      terms.push(residual.term);
      return terms;
    }, []);
  }

  get leaseTerms(): LeaseTerm[] {
    const types = {new: "newTerms", certified: "certUsedTerms"};
    const termType = types[ this.vehicleCondition ] || "";
    // const defaultTerms = this.leaseDefault[ termType ] || [];
    const leaseTerms = [];

    this.leaseResiduals.forEach((residual: LeaseResidual) => {
      leaseTerms.push(residual);
    });

    /*
    defaultTerms.forEach((term: number) => {
      const residual = this.leaseResiduals.find(item => term === item.term);
      if (residual) { leaseTerms.push(residual); }
    });
    */

    return leaseTerms;
  }

  /*get leaseLoyaltyProgram() {
    let result: number;
    this.vehicleIncentives.forEach(ig => {
      if (ig.incentiveGroupName === "Toyota Lease Loyalty Program") {
        result = ig.incentive.cashDetails.otherRebate;
      }
    });
    return result;
  }*/

  /*get leaseOfferSelected(): boolean {
    const inc = this.dealIncentives.filter((i: DealIncentive) => {
      if (i.leaseOffer) {
        if (i.leaseOffer.leaseOfferTerms) {
          const leaseOfferTerms = i.leaseOffer.leaseOfferTerms || [];
          const selectedLeaseOfferTermIndex = leaseOfferTerms.findIndex(t => {
            return t.term === this.uiState.selectedLeaseTerm;
          });
          if (selectedLeaseOfferTermIndex < 0) {
            return;
          }
          const selectedLeaseOfferTerm = leaseOfferTerms[ selectedLeaseOfferTermIndex ];
          if (selectedLeaseOfferTerm.tieredLeaseMFs[ this.selectedCreditTier ]) {
            return i;
          }
          if (selectedLeaseOfferTerm.tieredLeaseMFs.length > 0) {
            if (selectedLeaseOfferTerm.leaseSubvention > 0) {
              return i;
            }
            if (selectedLeaseOfferTerm.cashAtSigning >= 0) {
              return i;
            }
          }
        }
      }
    })[ 0 ];
    return !!inc;
  }*/

  // FORM ACTIONS & HELPERS

  isCurrentlySelectedResidual(term: number, miles: number) {
    let milesDrivenPerYear = this.milesDrivenPerYear;
    if (milesDrivenPerYear > 15000) {
      milesDrivenPerYear = 15000;
    }
    return this.uiState.selectedLeaseTerm === term && milesDrivenPerYear === miles;
  }

  onSelectLeaseDeal() {
    console.log("Lease Deal Selected");
    if (this.leaseSelected === true) { return; }
    this.submitDealType.emit(DealType.Lease);
  }

  onSelectLeaseTerm(term: number) {
    // console.log("Selected Lease Term:", term);
    this.onSelectLeaseDeal();
    this.submitLeasingTerm.emit(term);
  }

  submitLeaseForm = () => {
    const {pristine, invalid, value} = this.leaseForm;

    //console.log("Lease Options start:",this.leaseOptions)

    const leaseOptions: Partial<LeaseOptions> = {
      activeMoneyFactor: +value.activeMoneyFactor,
      acquisitionFee: +value.acquisitionFee,
      subventionCashOverride: +value.subventionCash,
      subventionCashDisabled: value.subventionCashDisabled,
      rateMarkup: +value.rateMarkup
    };

    //console.log(this.leaseForm)

    const selectedLeaseTermIndex = this.findSelectedLeaseTermIndex();
    if (selectedLeaseTermIndex >= 0) {
      const leaseTerm = pathOr(0, [
        "leaseOptions", "selectedLeaseTerm"
      ], this);
      if (leaseTerm) {
        const name = "moneyFactor" + leaseTerm;
        if (name) {
          this.leaseTerms[ selectedLeaseTermIndex ].customMoneyFactor =
            (this.leaseTerms[ selectedLeaseTermIndex ].moneyFactor !== +value[ name ]) ?
              true : this.leaseTerms[ selectedLeaseTermIndex ].customMoneyFactor;
          this.leaseTerms[ selectedLeaseTermIndex ].moneyFactor = +value[ name ];
        }
      }
    }

    //console.log("Lease Options end:",leaseOptions)

    leaseOptions.leaseTerms = this.leaseTerms;
    this.activateIncentive();
    this.submitLeaseOptions.emit(leaseOptions);

    /*
    const newGroup: DealIncentive = {
      incentiveGroupName: "leaseRebate"
    };
    this.dealIncentives.push(newGroup);
    this.incentivesService.onUpdateDealIncentive(newGroup, []);
    */

    this.leaseForm.markAsPristine();
  };

  findSelectedLeaseTermIndex() {
    const term = this.uiState.selectedLeaseTerm;
    return this.leaseTerms.findIndex(t => t.term === term);
  }

  /*submitMiscLeaseForm = () => {
    const {pristine, invalid, value} = this.miscLeaseForm;
    if (pristine || invalid) { return; }

    const leaseOptions: Partial<LeaseOptions> = {
      additionalCashDue: +value.additionalCashDue,
      prepaidMiles: value.prepaidMiles
    };

    this.submitLeaseOptions.emit(leaseOptions);
    this.miscLeaseForm.markAsPristine();
  };*/

  submitExcessMilesForm = () => {
    const {pristine, invalid, value} = this.excessMilesForm;
    if (pristine || invalid) { return; }

    const leaseOptions: Partial<LeaseOptions> = {
      prepaidMiles: value.prepaidMiles
    };

    this.submitLeaseOptions.emit(leaseOptions);
    this.miscLeaseForm.markAsPristine();
  };

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  };

  touchedInvalid(formName: string, controlName: string): boolean {
    const control = this[ formName ].get(controlName);
    return control.touched && control.invalid;
  }

  updateEstMPY(term: number, milesDrivenPerYear: number): void {
    this.onSelectLeaseTerm(term);
    const vehicleNeeds: Partial<VehicleNeeds> = {milesDrivenPerYear, plannedLengthOfOwnership: this.plannedLengthOfOwnership};
    this.store.dispatch(dealActions.setVehicleNeeds(vehicleNeeds));
  }

  /*public onUpdateIncentives(incentives: DealIncentive[]): void {
    this.submitIncentives.emit(incentives);
  }*/

  public selectLeaseTerm(target: any): void {
    const selectedLeaseTerm: number = parseInt(target.value);
    this.onSelectLeaseTerm(selectedLeaseTerm);
  }

  protected readonly DealType = DealType;
}
