<!-- TOP Navigation -->
<nav class="navbar top-nav app-bg-primary">

  <div class="d-flex flex-row" style="align-items: center;flex-grow:2;gap:0 0.5rem;flex-wrap:wrap;margin-bottom:0.25rem">
    <div class="navbar-brand d-flex pl-2 py-2 bg-white">
      <img class="logo" alt="northstar logo" src="/static/images/icons/NstarFpoIcon@512px.png" (click)="logoClicked()"/>
      <div class="logo-separator"></div>
      <img class="bt-logo" alt="bt logo" appDealerLogo (click)="btLogoClicked($event)"/>
      <div class="version-label">v.{{ currentRelease }}</div>
    </div>
    <button
      *ngIf="cnaService?.selectedOptions?.length>0"
      class="btn btn-sm rounded-pill app-btn-success cna-list-button"
      data-toggle="modal"
      data-target="#cna-modal" style="flex-grow:2;display: flex;justify-content: flex-end">
      <div class="cna-list-item" *ngFor="let item of cnaService?.selectedOptions;let i=index">
        <img [src]='getOptionIcon(item)' style="width:40px;height:40px"> <b>{{ item }}</b>
      </div>
    </button>
    <div style="display:flex;gap:0.5rem;flex-grow:2;justify-content: flex-end">

      <button
        class="btn btn-sm rounded-pill app-btn-success grey-button"
        (click)="openRouteOneTab()">
        <i class="material-icons app-text-white">check</i>
        Credit Check
      </button>

      <button
        *ngIf="isSysAdmin() || isSalesManager()"
        class="btn btn-sm rounded-pill app-btn-success grey-button"
        (click)="getInventory2()">
        <i class="material-icons app-text-white">download</i>
        Get Inventory
      </button>

      <button
        *ngIf="isSysAdmin() || isSalesManager()"
        class="btn btn-sm rounded-pill app-btn-success grey-button"
        (click)="notificationsClicked()">
        <i class="material-icons app-text-white">notifications</i>
        {{ countNotifications$() | async }} Alerts
      </button>
      <button
        class="btn btn-sm rounded-pill app-btn-success cna-button"
        data-toggle="modal"
        data-target="#cna-modal">
        <i class="material-icons app-text-white">person</i>
        <span style="margin:0">{{ ((cnaService.firstName || cnaService.lastName) ? (cnaService.firstName || '') + ' ' + (cnaService.lastName ? cnaService.lastName.toUpperCase().substr(0, 1) + '.' : '') : 'New Customer') }}</span>
      </button>
      <div class="dropdown">
        <button
          class="btn btn-sm rounded-pill app-btn-success user-button"
          data-toggle="dropdown">
          <i class="material-icons app-text-white">badge</i>
          {{ currentUser.firstName | titlecase }} {{ currentUser.lastName.toUpperCase().substr(0, 1) }}.
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <div class="px-3 font-weight-bold small">{{ userName | titlecase }}</div>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()">Log Out</a>
          <div *ngIf="adminRoutes.length > 0">
            <div class="dropdown-divider"></div>
            <div class="px-3 font-weight-bold small">Admin</div>
            <div class="dropdown-divider"></div>
            <a *ngFor="let route of adminRoutes" [routerLink]="route.routerLink" class="dropdown-item">{{ route.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<!-- SUB Navigation -->
<nav class="context-navbar context-navbar-layout" style="grid-template-columns: unset!important;grid-gap:0;padding:0!important;">
  <section class="header-bar-container context-navbar-col-right-layout" style="flex: 1 1 auto;display:flex!important;width:100%;grid-gap:0!important;border-bottom:1px solid #375320!important;">
    <ng-container *ngFor="let route of standardRoutes; let i = index;">
      <!--ng-container *ngIf="route.title=='Credit Check'">
        <button
          class="header-button btn btn-sm rounded-pill border-white flex-grow-1" style="outline-width: 0!important;outline:none!important;"
          [ngClass]="isActiveRoute(route.routerLink) ? 'app-btn-success' : 'app-btn-neutral'"
          (click)="openRouteOneTab()">
          {{ route.title }}
        </button>
      </ng-container-->
      <ng-container *ngIf="route.title!='Credit Check'">
        <button
          class="header-button btn btn-sm rounded-pill border-white " style="flex: 1 1 auto;;outline-width: 0!important;outline:none!important;"
          [ngClass]="isActiveRoute(route.routerLink) ? 'app-btn-success' : 'app-btn-neutral'"
          (click)="onSelectRoute(route.routerLink)">
          {{ route.title }}
        </button>
      </ng-container>
      <div *ngIf="i!=standardRoutes.length-1" style="height: 100%;border-left:1px solid #9ba990!important;min-width:1px;max-width:1px;"></div>
    </ng-container>
  </section>
  <section class="context-navbar-col-left-layout" *ngIf="isContextual() || showTeamSelect()">
    <!--app-manager-select *ngIf="showTeamSelect()" style="min-width: 200px;"></app-manager-select-->
    <app-search-form *ngIf="isContextual()" [placeholder]="'Search'" style="width: 100%"></app-search-form>
  </section>
</nav>
<!-- CNA Modal -->
<div id="cna-modal" #cnaModal class="modal fade cna-modal">
  <div class="modal-dialog p-2 p-sm-4 m-0" style="max-width: 100vw">
    <div class="modal-content modal-body">
      <button
        class="close"
        style="position: absolute; top: 1rem; right: 1.5rem"
        type="button"
        data-dismiss="modal"
        #closeModal>
        <span>&times;</span>
      </button>
      <h3 id="modal-basic-title">Customer Priorities</h3>
      <div class="section-info" style="margin-top:5px;"><b>Optional:</b> Enter the customer's name below.</div>
      <section class="model-info" style="margin-top:10px;width: 100%;display: flex">
        <span>Customer's Name:</span>
        <input placeholder="First Name" [ngModel]="cnaService.firstName" (ngModelChange)="updateCustomerFirstName($event)">
        <input placeholder="Last Name" [ngModel]="cnaService.lastName" (ngModelChange)="updateCustomerLastName($event)">
      </section>
      <div class="section-info"><b>Recommended:</b> Drag and drop the customer's top priorities.</div>
      <section class="wants">
        <div class="cna-list-container">
          <h2>Unselected Priorities</h2>
          <div
            id="sourceList"
            #todoList="cdkDropList"
            cdkDropList
            [cdkDropListData]="cnaService.allOptions"
            [cdkDropListConnectedTo]="[doneList]"
            class="cna-item-list"
            (cdkDropListDropped)="drop($event)">
            <div class="cna-list-item" *ngFor="let item of cnaService.allOptions" cdkDrag>
              <img [src]='getOptionIcon(item)'><span>{{ item }}</span>
              <div *cdkDragPreview class="cna-list-item" style="background: #fff!important;color:#000!important;padding:10px!important;"><img [src]='getOptionIcon(item)' style="width:40px;height:40px"><span>{{ item }}</span></div>
            </div>
          </div>
        </div>
        <div class="cna-list-container">
          <h2>Selected Priorities</h2>
          <div
            id="targetList"
            #doneList="cdkDropList"
            cdkDropList
            [cdkDropListData]="cnaService.selectedOptions"
            [cdkDropListConnectedTo]="[todoList]"
            class="cna-item-list"
            (cdkDropListDropped)="drop($event)">
            <div class="cna-list-item" *ngFor="let item of cnaService.selectedOptions" cdkDrag>
              <img [src]='getOptionIcon(item)'><span>{{ item }}</span>
              <div *cdkDragPreview class="cna-list-item" style="background: #fff!important;color:#000!important;padding:10px!important;"><img [src]='getOptionIcon(item)' style="width:40px;height:40px"><span>{{ item }}</span></div>
            </div>
          </div>
        </div>
      </section>
      <div style="text-align: right;margin-top:15px;display: flex;align-items: center;justify-content: flex-end;gap:15px">
        <button (click)="resetForm()" mat-raised-button color="warn">Reset Form</button>
        <button mat-raised-button data-dismiss="modal" class="green-button">Done</button>
      </div>
    </div>
  </div>
</div>
<!-- Get Inventory Modal -->
<div id="get-inventory-modal" #getInventoryModal class="modal fade cna-modal">
  <div class="modal-dialog p-2 p-sm-4 m-0" style="max-width: 100vw">
    <div class="modal-content modal-body">
      <button
        class="close"
        style="position: absolute; top: 1rem; right: 1.5rem"
        type="button"
        data-dismiss="modal"
        #closeModal>
        <span>&times;</span>
      </button>
      <h3 id="modal-basic-title2">Get Inventory Options</h3>
      <hr style="margin-top:0">
      <h3 style="font-size:20px">Get Single Vehicle</h3>
      <p>Loading a single vehicle typically takes less then 15 seconds.</p>
      <div style="display: flex;gap:5px;align-items: center">
        <input placeholder="Enter VIN" [ngModel]="getInventoryVin" (ngModelChange)="updateGetInventoryVin($event)" style="padding: 0 8px;min-width:240px;border-radius: 4px;height:36px;border:1px solid #ccc">
        <button mat-raised-button class="blue-button" (click)="getInventoryForVin()" [disabled]="!getInventoryVin">Get Single Vehicle</button>
      </div>
      <hr>
      <h3 style="font-size:20px">Get All Inventory</h3>
      <p>Retrieving all inventory may take up to 1 hour.</p>
      <div style="display: flex;gap:5px;align-items: center;margin-bottom:16px">
        <button mat-raised-button class="blue-button" (click)="getAllInventory()">Get All Inventory</button>
      </div>
    </div>
  </div>
</div>
