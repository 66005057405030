<form [formGroup]="salesTeamForm" class="column-layout">
  <div class="select-field-container">
    <div [ngClass]="{ 'app-text-danger': touchedInvalid('salesPersonEmployeeId') }">Sales Person</div>
    <select style="height: 28px" class="form-group float-right w-65" formControlName="salesPersonEmployeeId" (change)="autoSubmitSalesPerson()">
      <option disabled value="">Select</option>
      <option *ngFor="let employee of salesPeople" [value]="employee.employeeId">{{ employee.firstName | titlecase }} {{ employee.lastName | titlecase }}</option>
    </select>
  </div>
  <div class="select-field-container">
    <div [ngClass]="{'app-text-danger': touchedInvalid('salesManagerEmployeeId')}">Sales Manager</div>
    <select style="height: 28px" class="form-group float-right w-65" formControlName="salesManagerEmployeeId" (change)="autoSubmitSalesManager()">
      <option disabled value="">Select</option>
      <option *ngFor="let employee of salesManagers" [value]="employee.employeeId">{{ employee.firstName | titlecase }} {{ employee.lastName | titlecase }}</option>
    </select>
  </div>
</form>
