import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-no-deal-type-selector-panel',
  templateUrl: './no-deal-type-selector-panel.component.html',
  styleUrls: ['./no-deal-type-selector-panel.component.scss']
})
export class NoDealTypeSelectorPanelComponent implements OnInit {
  @Output() submitDealType = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}
