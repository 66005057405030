<form [formGroup]="vehicleForm">

  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'stockNumber'"
                    [name]="'Stock #'"
                    [fieldValue]="vehicle.stockNumber"
                    [type]="'text'"
                    [blur]="autoSubmitStockNumber">
    <button class="btn app-btn-success rounded-pill px-2 py-0 small-green-button"
            style="font-size:0.8rem;flex-shrink:0;margin-left:20px;"
            type="button"
            tabindex="-1"
            (click)="onSelectInventory()">
      BT Inventory
    </button>
  </app-simple-input>


  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'modelNumber'"
                    [name]="'Model #'"
                    [fieldValue]="vehicle.modelNumber"
                    [type]="'text'"
                    [blur]="autoSubmitForm"
                    [readOnly]="true">
  </app-simple-input>

  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'vin'"
                    [name]="'VIN'"
                    [fieldValue]="vehicle.vin"
                    [type]="'text'"
                    [blur]="autoSubmitForm"
                    [readOnly]="true">
  </app-simple-input>


  <app-simple-input [parentForm]="vehicleForm"
                    formCtrlName="bodyStyle"
                    name="Body Style"
                    [fieldValue]="vehicle.bodyStyle"
                    type="text"
                    [readOnly]="true"
                    [blur]="autoSubmitForm">
  </app-simple-input>

  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'condition'"
                    [name]="'Condition'"
                    [fieldValue]="condition"
                    [type]="'text'"
                    [blur]="autoSubmitForm">
  </app-simple-input>

  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'year'"
                    [name]="'Year'"
                    [fieldValue]="vehicle.year"
                    [type]="'number'"
                    [maxLength]="4"
                    [blur]="autoSubmitForm"
                    inputmode="numeric">
  </app-simple-input>

  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'make'"
                    [name]="'Make'"
                    [fieldValue]="vehicle.make"
                    [type]="'text'"
                    [blur]="autoSubmitForm">
  </app-simple-input>

  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'model'"
                    [name]="'Model'"
                    [fieldValue]="vehicle.model"
                    [type]="'text'"
                    [blur]="autoSubmitForm">
  </app-simple-input>

  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'trim'"
                    [name]="'Trim'"
                    [fieldValue]="vehicle.trim"
                    [type]="'text'"
                    [blur]="autoSubmitForm">
  </app-simple-input>

  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'odometer'"
                    [name]="'Odometer'"
                    [fieldValue]="vehicle.odometer"
                    [type]="'number'"
                    [blur]="autoSubmitForm"
                    inputmode="numeric">
  </app-simple-input>

  <app-simple-input [parentForm]="vehicleForm"
                    [formCtrlName]="'mpg'"
                    [name]="'Comb. MPG'"
                    [fieldValue]="vehicle.mpg"
                    [type]="'number'"
                    [blur]="autoSubmitForm"
                    inputmode="numeric">
  </app-simple-input>

  <ng-container *ngIf="vehicle.isUsed || vehicle.isCertified">

    <app-simple-input [parentForm]="vehicleForm"
                      [formCtrlName]="'lot'"
                      [name]="'LOT'"
                      [fieldValue]="vehicle.lot"
                      [type]="'text'"
                      [blur]="autoSubmitForm">
    </app-simple-input>

    <app-simple-input [parentForm]="vehicleForm"
                      [formCtrlName]="'plateNumber'"
                      [fieldValue]="deal.plateNumber"
                      [name]="'Lic. Plate'"
                      [type]="'text'"
                      [blur]="autoSubmitForm">
    </app-simple-input>

    <app-simple-input [parentForm]="vehicleForm"
                      [formCtrlName]="'plateExpires'"
                      [fieldValue]="deal.plateExpires"
                      [name]="'Plate Exp.'"
                      [type]="'text'"
                      [blur]="autoSubmitForm">
    </app-simple-input>

  </ng-container>

  <!--app-simple-input-dropdown [parentForm]="vehicleForm"
                             [formCtrlName]="'bodyStyle'"
                             [name]="'Body Style'"
                             (valueChanged)="bodyStyleChanged($event)"
                             [fieldValue]="vehicle.bodyStyle"
                             [type]="'dropdown'"
                             [dropdownValues]="['CAR','TRUCK','VAN','SUV','OTHER']"
                             [blur]="autoSubmitForm">
  </app-simple-input-dropdown-->

</form>
