import { DealState, DealType, initialDealState } from "../state/deal/deal";
import { Action, createReducer, on } from "@ngrx/store";
import * as DealActions from "../actions/deal.actions";
import * as R from "ramda";
import * as VehicleActions from "../actions/vehicle.actions";
import { initialFinanceOptionsEditsState } from "../state/deal/financing";
import { InsuranceProduct } from "../../models";

const reducer = createReducer(
  initialDealState,
  on(DealActions.setInitialized, (state, {initialized}) => {
      return ({...state, initialized});
    }
  ),
  on(DealActions.setContractDate, (state, {contractDate}) => {
      return ({...state, contractDate});
    }
  ),
  on(DealActions.setAccessories, (state, {accessories}) => {
    const val = {
      ...state,
      accessories: [...accessories]
    };
    return val;
  }),
  on(DealActions.setCoSigner, (state, {coSigner}) =>
    ({...state, coSigner: {...state.coSigner, ...coSigner}})
  ),
  on(DealActions.setCustomer, (state, {customer}) => {
      return ({
        ...state,
        customer: {...state.customer, ...customer},
        // financeOptionsEdits: initialFinanceOptionsEditsState
      });
    }
  ),
  on(
    DealActions.setCustomFinanceRate,
    (state, {customerProvidedInterestRate, customSelected, activeInterestRate}) => {
      // if custom rate is selected, then unselect incentive rate, otherwise retain current incentive rate
      const incentiveSelected = customSelected ? false : state.financeOptions.incentiveSelected;
      const activeInterestRateUpdated = activeInterestRate ? activeInterestRate : state.financeOptions.activeInterestRate;
      return ({
        ...state,
        financeOptions: {
          ...state.financeOptions,
          customSelected,
          customerProvidedInterestRate,
          incentiveSelected, activeInterestRate: activeInterestRateUpdated
        }
      });
    }
  ),
  on(DealActions.setCustomerStateCounty, (dealState, {state, county}) => {
      return ({
        ...dealState,
        customer: {...dealState.customer, state, county},
      });
    }
  ),
  on(DealActions.setPlate, (state, {plateExpires, plateNumber}) => {
    return ({...state, plateExpires, plateNumber});
  }),
  on(DealActions.setDealOdometer, (state, {odometer}) =>
    ({...state, odometer})
  ),
  on(DealActions.setDisplayTerms, (state, {displayTerms}) => {
    return {
      ...state, displayTerms
    };
  }),
  on(DealActions.setInsuranceInfo, (state, {insuranceInfo}) => {
      return ({...state, insuranceInfo});
    }
  ),
  on(DealActions.setDealType, (state, {dealType}) => {
    return {
      ...state,
      leaseOptions: {
        ...state.leaseOptions,
        leaseSelected: dealType === DealType.Lease
      },
      financeOptions: {
        ...state.financeOptions,
        incentiveSelected: dealType === DealType.Cash ?
          false :
          state.financeOptions.incentiveSelected,
        financeSelected: dealType === DealType.Finance,
        cashPurchase: dealType === DealType.Cash
      }
    };
  }),
  on(DealActions.setFinancingTerm, (state, {selectedFinancingTerm}) => {
    return {
      ...state,
      financeOptions: {
        ...state.financeOptions,
        selectedFinancingTerm,
        // customSelected: false,
        // customerProvidedFinancingTerm: selectedFinancingTerm,
        financeSelected: true,
        cashPurchase: false
      },
      leaseOptions: {
        ...state.leaseOptions,
        leaseSelected: false
      },
    };
  }),
  on(DealActions.setLeasingTerm, (state, {selectedLeaseTerm}) => ({
    ...state,
    leaseOptions: {
      ...state.leaseOptions,
      selectedLeaseTerm,
      leaseSelected: true
    },
    financeOptions: {
      ...state.financeOptions,
      financeSelected: false,
      cashPurchase: false
    }
  })),
  on(DealActions.pushVerifiedForm, (state, {formName}) => {

    state.verifiedForms = Array.isArray(state.verifiedForms) ? state.verifiedForms : [];
    const idAlreadyExists = state.verifiedForms.indexOf(formName) > -1;
    const verifiedForms = state.verifiedForms.slice();

    if (!idAlreadyExists) {
      verifiedForms.push(formName);
    }

    return {
      ...state,
      verifiedForms
    };
  }),
  on(DealActions.updateSelectedDealerAccessories, (state, {selectedDealerAccessories}) => {
    return {...state, selectedDealerAccessories};
  }),
  on(DealActions.removeVerifiedForm, (state, {formName}) => {

    state.verifiedForms = Array.isArray(state.verifiedForms) ? state.verifiedForms : [];
    const idAlreadyExists = state.verifiedForms.indexOf(formName) > -1;
    let verifiedForms = state.verifiedForms.slice();

    if (idAlreadyExists) {
      verifiedForms = verifiedForms.filter(id => id != formName);
    }
    return {
      ...state,
      verifiedForms
    };
  }),
  on(DealActions.setFinanceOptions, (state, {financeOptions}) => {
    return ({...state, financeOptions: {...state.financeOptions, ...financeOptions}});
  }),
  on(DealActions.setPlateTransfer, (state, {plateTransfer}) => {
    return ({...state, financeOptions: {...state.financeOptions, plateTransfer}});
  }),
  on(DealActions.setFinanceOptionsEdits, (state, {financeOptionsEdits}) => {
    return ({
      ...state, financeOptionsEdits: {
        ...state.financeOptionsEdits,
        ...financeOptionsEdits
      }
    });
  }),
  on(DealActions.clearFinanceOptionsEdits, (state) => {
    return ({...state, financeOptionsEdits: initialFinanceOptionsEditsState});
  }),
  on(DealActions.setLienHolder, (state, {lienHolder}) => {
    return ({...state, lienHolder});
  }),
  on(DealActions.setLeaseOptions, (state, {leaseOptions}) =>
    ({...state, leaseOptions: {...state.leaseOptions, ...leaseOptions}})
  ),
  on(DealActions.setInsuranceProducts, (state, {insuranceProducts}) => {
    if (!state.insuranceProducts) {
      state.insuranceProducts = [];
    }
    const products = (insuranceProducts || []).slice();

    /* if new insurance products have a removed product,
    save the missing state product to disabled products */
    /* if new insurance products have an additional product,
    remove that product from the disabled products. */
    const stateProductKeys = state.insuranceProducts ? state.insuranceProducts.map((product: InsuranceProduct) => product.productKey) : [];
    const newProductKeys = insuranceProducts ? insuranceProducts.map((product: InsuranceProduct) => product.productKey) : [];
    const addedProductKeys = R.difference(newProductKeys, stateProductKeys);
    const removedProductKeys = R.difference(stateProductKeys, newProductKeys);
    const disabledInsuranceProducts: InsuranceProduct[] = R.clone(state.disabledInsuranceProducts) || [];
    addedProductKeys.forEach(productKey => {
      const i = disabledInsuranceProducts ? disabledInsuranceProducts.findIndex(p => p.productKey === productKey) : -1;
      if (i >= 0) {
        disabledInsuranceProducts.splice(i, 1);
      }
    });
    removedProductKeys.forEach(productKey => {
      const i = state.insuranceProducts ? state.insuranceProducts.findIndex(p => p.productKey === productKey) : -1;
      if (i >= 0) {
        disabledInsuranceProducts.push(state.insuranceProducts[ i ]);
      }
    });

    return ({
      ...state,
      insuranceProducts: products,
      insuranceProductsSet: true,
      disabledInsuranceProducts
    });
  }),
  on(DealActions.insuranceProductsSet, (state, {insuranceProductsSet}) =>
    ({...state, insuranceProductsSet})
  ),
  on(DealActions.incentivesSet, (state, {incentivesSet}) =>
    ({...state, incentivesSet})
  ),
  on(DealActions.updateInsuranceProduct, (state, {insuranceProduct}) => {
    const newInsuranceProducts = R.clone(state.insuranceProducts).map((product: InsuranceProduct) => {
      if (product.productKey !== insuranceProduct.productKey) {
        // This isn't the item we care about - keep it as-is
        return product;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...product,
        ...insuranceProduct
      };
    });
    return {...state, insuranceProducts: newInsuranceProducts};
  }),
  on(DealActions.setSalesManager, (state, {salesManager}) => ({
    ...state,
    managerId: salesManager.employeeId,
    salesManager: `${salesManager.firstName} ${salesManager.lastName}`
  })),
  on(DealActions.setSalesPerson, (state, {salesPerson}) => ({
    ...state,
    salesId: salesPerson.employeeId,
    salesPerson: `${salesPerson.firstName} ${salesPerson.lastName}`,
    salesPersonPhone: salesPerson.smsCellNumber
  })),
  on(DealActions.setTradeIn, (state, {tradeIn}) =>
    ({...state, tradeIn: {...state.tradeIn, ...tradeIn}})
  ),
  on(DealActions.setTradeInOwner, (state, {tradeInOwner}) =>
    ({...state, tradeInOwner: {...state.tradeInOwner, ...tradeInOwner}})
  ),
  on(DealActions.setTradeInOwner2, (state, {tradeInOwner2}) =>
    ({...state, tradeInOwner2: {...state.tradeInOwner2, ...tradeInOwner2}})
  ),
  on(DealActions.setTradeIn2, (state, {tradeIn2}) =>
    ({...state, tradeIn2: {...state.tradeIn2, ...tradeIn2}})
  ),
  on(DealActions.setTradeIn2Owner, (state, {tradeIn2Owner}) =>
    ({...state, tradeIn2Owner: {...state.tradeIn2Owner, ...tradeIn2Owner}})
  ),
  on(DealActions.setTradeIn2Owner2, (state, {tradeIn2Owner2}) =>
    ({...state, tradeIn2Owner2: {...state.tradeIn2Owner2, ...tradeIn2Owner2}})
  ),
  on(DealActions.setVehicleNeeds, (state, {vehicleNeeds}) =>
    ({...state, vehicleNeeds: {...state.vehicleNeeds, ...vehicleNeeds}})
  ),
  on(DealActions.setIncentives, (state, {incentives}) =>
    ({...state, incentives})
  ),
  on(VehicleActions.getVehicleSuccess, (state, {vehicle: {stockNumber}}) =>
    ({...state, stockNumber})
  ),
  on(DealActions.clearDeal, state => {
    return initialDealState;
  }),
  on(DealActions.setSignaturesSuccess, (state, {buyerSignatureSaved, coBuyerSignatureSaved}) => {
      return ({
        ...state,
        customer: {
          ...state.customer,
          signatureUrl: buyerSignatureSaved ? "1" : ""
        },
        coSigner: {
          ...state.coSigner,
          signatureUrl: coBuyerSignatureSaved ? "1" : ""
        }
      });
    }
  ),
  on(DealActions.submitDealSubmitModalForm,
    (state, {customer, coSigner, tradeIn, tradeInOwner, tradeInOwner2, tradeIn2, tradeIn2Owner, tradeIn2Owner2, insuranceInfo, comments}) =>
      ({...state, customer, coSigner, tradeIn, tradeInOwner, tradeInOwner2, tradeIn2, tradeIn2Owner, tradeIn2Owner2, insuranceInfo, comments})
  ),
  on(VehicleActions.setVehicle, (state, {vehicle}) => {
    return {
      ...state,
      insuranceProducts: null,
      insuranceProductsSet: false,
      stockNumber: vehicle.stockNumber,
      vehicle: `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`
    };
  }),
  on(DealActions.dealApiSuccess, (state, {deal}) => {
      return deal;
    }
  )
);

export function dealStateReducer(state: DealState | undefined, action: Action) {
  return reducer(state, action);
}
