<div
  class="section-header"
  style="margin-top:7px!important;display:flex;align-items: center;justify-content: space-between">
  <span>Lienholder</span>
  <a
    *ngIf="!readOnly && !customLienHolderSelected && this.selectedLenderIndex >=0"
    (click)="clearLienHolder()"
    class="small-blue-button">
    Clear
  </a>
</div>
<div *ngIf="!customLienHolderSelected" class="select-field-container">
  <select
    #lenderSelect
    (change)="selectLender($event.target.value)"
    class="form-control border border-primary color" style="height: 28px;">
    <option value="" disabled>Select Lienholder</option>
    <option *ngFor="let lender of lenders; let i = index;" [selected]="i === selectedLenderIndex" [value]="i">{{ lender.name }}</option>
  </select>
</div>

<form *ngIf="customLienHolderSelected" [formGroup]="customLienHolderForm">

  <app-simple-input
    [parentForm]="customLienHolderForm"
    [blur]="submitCustomLienHolderForm"
    [keydown]="flagChange"
    [change]="flagChange"
    formCtrlName="name"
    name="Name"
    type="string">
  </app-simple-input>

  <app-simple-input
    [parentForm]="customLienHolderForm"
    [blur]="submitCustomLienHolderForm"
    [keydown]="flagChange"
    [change]="flagChange"
    formCtrlName="phone"
    name="Phone"
    type="string">
  </app-simple-input>

  <app-simple-input
    [parentForm]="customLienHolderForm"
    [blur]="submitCustomLienHolderForm"
    [keydown]="flagChange"
    [change]="flagChange"
    formCtrlName="address"
    name="Address"
    type="string">
  </app-simple-input>

  <app-simple-input
    [parentForm]="customLienHolderForm"
    [blur]="submitCustomLienHolderForm"
    [keydown]="flagChange"
    [change]="flagChange"
    formCtrlName="city"
    name="City"
    type="string">
  </app-simple-input>

  <app-simple-input
    [parentForm]="customLienHolderForm"
    [blur]="submitCustomLienHolderForm"
    [keydown]="flagChange"
    [change]="flagChange"
    formCtrlName="state"
    name="State"
    type="string">
  </app-simple-input>

  <app-simple-input
    [parentForm]="customLienHolderForm"
    [blur]="submitCustomLienHolderForm"
    [keydown]="flagChange"
    [change]="flagChange"
    formCtrlName="zip"
    name="Zip"
    type="string">
  </app-simple-input>

  <app-simple-input
    [parentForm]="customLienHolderForm"
    [blur]="submitCustomLienHolderForm"
    [keydown]="flagChange"
    [change]="flagChange"
    formCtrlName="id"
    name="ID"
    type="string">
  </app-simple-input>
</form>

<div *ngIf="!readOnly">
  <button
    *ngIf="!customLienHolderSelected"
    (click)="selectCustomLienHolder()"
    class="btn app-btn-success rounded-pill px-2 py-0 small-green-button" style="font-size: 0.8rem;margin-left:5px;margin-bottom:5px;margin-top:2px;">Enter Custom Lienholder
  </button>
  <button
    *ngIf="customLienHolderSelected"
    (click)="cancelSelectCustomLienHolder()"
    class="btn app-btn-success rounded-pill px-2 py-0 small-green-button" style="font-size: 0.8rem;margin-left:5px;margin-bottom:5px;margin-top:4px;">Remove Custom Lienholder
  </button>
</div>
