<form [formGroup]="formGroup">
  <select
    class="form-control deal-type-select"
    formControlName="selectedDealType"
    (change)="onSelectedDealTypeChange($event.target)">
    <option [value]="undefined" disabled>
      Select...
    </option>
    <option value="finance">Finance</option>
    <option value="lease">Lease</option>
    <option value="cash">Cash</option>
  </select>
</form>
