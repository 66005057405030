<ng-container>
  <button
    class=" track-button btn rounded-pill px-2 py-0 mx-2 m-0"
    [ngClass]="'app-btn-success'"
    type="button"
  >
    Track
  </button>
  <!--<span [ngClass]="{ 'app-text-blue app-text-bold': hasEvent('TD') }">TD </span>
  <span [ngClass]="{ 'app-text-blue app-text-bold': hasEvent('OP') }">OP </span>
  <span [ngClass]="{ 'app-text-blue app-text-bold': hasEvent('TI') }">TI </span>
  <span [ngClass]="{ 'app-text-blue app-text-bold': hasEvent('AC') }">AC </span>
  <span [ngClass]="{ 'app-text-blue app-text-bold': hasEvent('OE') }">OE </span>
  <span [ngClass]="{ 'app-text-blue app-text-bold': hasEvent('PO') }">PO </span>-->
</ng-container>
