<form [formGroup]="tradeInForm" style="margin-bottom:2px">

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'year'"
    [name]="'Year'"
    [blur]="autoSubmitForm"
    [type]="'number'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'make'"
    [name]="'Make'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'model'"
    [name]="'Model'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'trim'"
    [name]="'Trim'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'bodyType'"
    [name]="'Body Type'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'color'"
    [name]="'Color'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'vin'"
    [name]="'VIN'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'license'"
    [name]="'Plate #'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'odometer'"
    [name]="'Odometer'"
    [blur]="autoSubmitForm"
    [type]="'number'"
    inputmode="numeric">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'stockNumber'"
    [name]="'Stock Number'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'tradeEstimate'"
    [name]="'Trade Value'"
    [blur]="autoSubmitForm"
    [pipe]="'currency'"
    [type]="'number'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'payOff'"
    [name]="'Payoff'"
    [pipe]="'currency'"
    [blur]="autoSubmitForm"
    [type]="'number'">
  </app-simple-input>

  <div
    class="sub-section-header">
    <span>Trade-In Lienholder</span>
    <a class="small-blue-button" *ngIf="!customLienHolderSelected" (click)="enableCustomLienHolder()">Custom</a>
    <a class="small-blue-button" *ngIf="tradeInForm.get('lienHolderName').value!='' || customLienHolderSelected" (click)="clearLienHolder()">Clear</a>
  </div>

  <div [style.display]="!customLienHolderSelected ? 'block' : 'none'" class="select-field-container">
    <select
      class="form-control border border-primary color"
      (change)="selectLender($event.target.value)">
      <option value="">Select Lienholder</option>
      <option *ngFor="let lender of lenders; let i = index;" [selected]="i === selectedLenderIndex" [value]="i">{{ lender.name }}</option>
    </select>
  </div>

  <div [style.display]="customLienHolderSelected ? 'block' : 'none'">
    <app-simple-input
      [parentForm]="tradeInForm"
      [forceUpdate]="forceChildFormUpdate"
      [blur]="autoSubmitForm"
      formCtrlName="lienHolderName"
      name="Lienholder"
      type="text">
    </app-simple-input>
  </div>

</form>
