<form [formGroup]="parentForm" class="column-layout">
  <div class="select-field-container">
    <div>Est. MPY</div>
    <select class="form-control border border-primary w-50 color" style="height: 28px" formControlName="milesDrivenPerYear" (change)="change()">
      <option value="" disabled>Select Miles</option>
      <option *ngFor="let option of milesPerYearOptions" [value]="option.miles">{{ option.title }}</option>
    </select>
  </div>
  <div class="select-field-container">
    <div>Est. Ownership</div>
    <select class="form-control border border-primary w-50 color" style="height: 28px" formControlName="plannedLengthOfOwnership" (change)="change()">
      <option [value]="" disabled>Select Years</option>
      <option *ngFor="let option of ownershipYearsOptions" [value]="option.years">{{ option.title }}</option>
    </select>
  </div>
</form>
