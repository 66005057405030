import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppService } from "../../../clearpath-module/services";
import { FORM_INPUT_REQS } from "src/app/app.config";

@Component({
  selector: "app-search-form",
  templateUrl: "./search-form.component.html",
  styleUrls: ["./search-form.component.scss"]
})
export class SearchFormComponent implements OnInit, OnDestroy {
  @Input() placeholder: string;
  private unsubscribe$ = new Subject();
  public REQS = FORM_INPUT_REQS;

  searchForm: FormGroup = this.formBuilder.group({
    searchString: [""]
  });

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.subToSearchString();
    this.placeholder = "Enter search here..."
  }

  ngOnDestroy() {
    this.appService.setSearch("");
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subToSearchString() {
    this.appService.selectSearchString()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((searchString: string) => {
        this.searchForm.patchValue({searchString});
      });
  }

  onSubmitSearchForm() {
    if (this.searchForm.dirty) {
      const {searchString} = this.searchForm.value;
      this.appService.setSearch(searchString.trim());
      this.searchForm.markAsPristine();
    }
  }

}
