<div
  class="cash app-text-light px-1 app-text-bold my-1 py-1 d-flex justify-content-between primary-section-header-block align-items-center">
  <app-deal-type-selector (submitDealType)="submitDealType.emit($event)"></app-deal-type-selector>
  <span class="app-text-secondary app-text-white " style="font-size:20px;">{{ totalVehicleCashPrice$ | async | currency }}</span>
</div>

<app-lender-select
  [lender]="lender"
  [lenderName]="lender.name"
  [flagChange]="flagChange">
</app-lender-select>

<!--<app-smw-rebates-form
  [dealIncentives]="dealIncentives"
  [vehicleIncentives]="vehicleIncentives"
  dealType="cash"
  (updateIncentives)="onUpdateIncentives($event)">
</app-smw-rebates-form>-->

<div class="section-header">Products</div>

<app-smw-insurance-products-form
  [dealDefaults]="dealDefaults"
  (flagUnsavedChanges)="flagChange()"
  type="cash">
</app-smw-insurance-products-form>

<!-- <app-simple-input [fieldValue]="totalInsuranceProductsPrice$ | async | currency"
                  [name]="'Total Protection Adds'"
                  [type]="'number'">
</app-simple-input> -->

<app-simple-input
  [fieldValue]="tradeInNetValue$ | async"
  name="Trade Net"
  type="number"
  pipe="currency">
</app-simple-input>

<app-simple-input
  [fieldValue]="totalVehicleCashPrice$ | async"
  name="Total Amount Due"
  type="number"
  pipe="currency"
  isBold="true">
</app-simple-input>
